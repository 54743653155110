import {PowderguideAuthentication} from "./powderguide-authentication";
import {PowderguideApi, PowderguideApiCacheDecorator, PowderguideApiInterface} from "./powderguide-api";
import {PowderguideApiTokenStorage} from "./powderguide-api-token-storage";
import {PowderguideComments} from "./powderguide-comments";
import {PowderguideRegistration} from "./powderguide-registration";
import {PowderguidePasswordReset} from "./powderguide-password-reset";

interface PowderguideParts {
    tokenStorage: PowderguideApiTokenStorage,
    powderguideApi: PowderguideApiInterface,
    powderguideAuthentication: PowderguideAuthentication,
    powderguideComments: PowderguideComments,
    powderguideRegistration: PowderguideRegistration,
    powderguidePasswordReset: PowderguidePasswordReset,
}

export function initializePowderguide(): PowderguideParts {
    const cacheElement = document.querySelector('meta[name="powderguide-api-cache"]');
    const cache = cacheElement ? parseInt(cacheElement.getAttribute('content').trim()) : 0;
    const cacheBustElement = document.querySelector('meta[name="powderguide-api-cache-bust"]');
    const cacheBust = cacheBustElement ? cacheBustElement.getAttribute('content').trim() : null;
    const commentUuidElement = document.querySelector('meta[name="powderguide-comment-uuid"]');
    const commentUuid = commentUuidElement ? commentUuidElement.getAttribute('content').trim() : null;
    const commentTargetElement = document.querySelector('meta[name="powderguide-comment-target"]');
    const commentTarget = commentTargetElement ? commentTargetElement.getAttribute('content').trim() : null;
    const lang = document.querySelector('html').getAttribute('lang');

    const tokenStorage = new PowderguideApiTokenStorage();
    let powderguideApi: PowderguideApiInterface = new PowderguideApi(tokenStorage, lang);

    if (cache > 0) {
        powderguideApi = new PowderguideApiCacheDecorator(
            powderguideApi,
            tokenStorage,
            cache,
            cacheBust
        );
    }
    const powderguideAuthentication = new PowderguideAuthentication(
        powderguideApi
    );

    const powderguideComments = new PowderguideComments(
        powderguideApi,
        powderguideAuthentication,
        commentTarget as 'entry'|'conditions_report',
        commentUuid,
    );

    const powderguideRegistration = new PowderguideRegistration(
        powderguideApi,
        (new URLSearchParams(window.location.search)).get('registration_token')
    )

    const powderguidePasswordReset = new PowderguidePasswordReset(
        powderguideApi,
        (new URLSearchParams(window.location.search)).get('password_reset_token')
    )

    return {
        tokenStorage,
        powderguideApi,
        powderguideAuthentication,
        powderguideComments,
        powderguideRegistration,
        powderguidePasswordReset,
    };
}

export async function killOldServiceWorkers(): Promise<void> {
    if (!navigator.serviceWorker || !navigator.serviceWorker.getRegistrations) {
        return;
    }

    const registrations = await navigator.serviceWorker.getRegistrations()
    for (const registration of registrations) {
        if (registration.scope.indexOf('_iframe') !== -1) {
            continue;
        }

        await registration.unregister()
    }
}
