import {PowderguideApiTokenStorage} from "./powderguide-api-token-storage";

export interface UserData {
    id: string,
    username: string,
    name: string,
    is_admin: boolean,
    is_cr_pro: boolean,
}

export interface Comment {
    uuid: string,
    comment: string,
    created_at: string,
    updated_at: string,
    commentator: {
        username: string,
        name: string,
    },
    translations: {
        comment: {
            sourceLanguage: string,
            en: string,
            de: string,
        }
    }
}

export interface Spot {
    uuid: string
    title: string
    country: string
    location: {
        lat: number
        long: number
    }
    url?: string
}

export interface SpotForMap extends Spot {
    conditionReport: {
        uuid: string
        author: {
            username: string
        },
        activityDate: string
        title: string
        counter: number
        season: string
        overallReview: {
            rating: number
        }
        snowCondition: {
            rating: number
        }
        snowQuality: {
            rating: number
        }
        mainImage: string,
    }
}

type PasswordResetStatus = 'success'|'error'|'invalid_token'|'token_expired'|'already_used'

export interface PowderguideApiInterface {
    login(username: string, password: string): Promise<UserData | null>;

    logout(): Promise<void>;

    getUser(): Promise<UserData | null>;

    loadSpotsForMap(activityAfter: string, activityBefore: string): Promise<SpotForMap[]>;

    loadLatestComments(limit: number): Promise<Comment[]>;

    loadComments(target: 'entry'|'conditions_report', uuid: string): Promise<Comment[]>;

    postComment(target: 'entry'|'conditions_report', uuid: string, comment: string): Promise<Comment>;

    deleteComment(uuid: string): Promise<void>;

    register(userProperties: RegistrationRequest): Promise<RegistrationResponse>;

    confirmRegistration(token: string): Promise<RegistrationConfirmationResponse>;

    resendRegistrationEmail(emailAddress: string): Promise<{ success: boolean }>;

    sendPasswordResetMail(emailAddress: string): Promise<SendPasswordResetMailResponse>;

    resetPassword(token: string, password: string, passwordConfirmation: string): Promise<ResetPasswordResponse>;
}

export interface SendPasswordResetMailResponse extends LaravelValidationError {
    success: boolean
}

export interface ResetPasswordResponse extends LaravelValidationError {
    success: boolean,
    status: PasswordResetStatus
}

export interface RegistrationRequest {
    username: string,
    password: string,
    password_confirmation: string,
    email_address: string,
    has_acknowledged_toc: boolean,
    wants_to_receive_newsletter: boolean,
    name?: string,
    address?: string,
    zip?: string,
    city?: string,
    country?: string,
    instagram_username?: string,
}

export interface LaravelValidationError {
    message: string,
    errors: {
        [property: string]: string[],
    }
}

export interface RegistrationResponse extends LaravelValidationError {
    success: boolean
}

export interface RegistrationConfirmationResponse {
    success: boolean,
    result: 'success'| 'error_invalid_token'| 'error_generic'| 'error_expired'| 'error_already_confirmed'| 'error_token_mismatch',
    user: UserData,
}

export class PowderguideApi implements PowderguideApiInterface {
    constructor(
        private tokenStorage: PowderguideApiTokenStorage,
        private lang: string|null,
    ) {
    }

    public async register(userProperties: RegistrationRequest): Promise<RegistrationResponse> {
        return this.statamicRequest('api/v2/register', 'POST', userProperties);
    }

    public async confirmRegistration(token: string): Promise<RegistrationConfirmationResponse> {
        return this.statamicRequest('api/v2/register/confirm', 'POST', { token: token });
    }

    public async resendRegistrationEmail(emailAddress: string): Promise<{ success: boolean }> {
        return this.statamicRequest('api/v2/register/resend-confirmation',  'POST', { email_address: emailAddress });
    }

    loadLatestComments(limit: number): Promise<Comment[]> {
        return this.statamicRequest<Comment[]>(`api/v2/comment/latest?limit=${limit}`, 'GET');
    }

    loadSpotsForMap(activityAfter: string, activityBefore: string): Promise<SpotForMap[]> {
        return this.statamicRequest<SpotForMap[]>(
            `api/v2/spot/map?activity_after=${activityAfter}&activity_before=${activityBefore}`,
            'GET',
        )
    }

    public async login(username: string, password: string): Promise<UserData | null> {
        const data = await this.statamicRequest<{ token: string }>('api/v2/authentication', 'POST', {
            username: username,
            password: password,
            device_id: 'website',
        })

        const token = data.token.split('|')[1];

        this.tokenStorage.store(token);
        return this.getUser();
    }

    public async logout(): Promise<void> {
        const token = this.tokenStorage.get();
        if (!token) {
            return;
        }

        await this.statamicRequest('api/v2/authentication', 'DELETE');
        this.tokenStorage.clear();
    }

    public async getUser(): Promise<UserData | null> {
        const token = this.tokenStorage.get();
        if (!token) {
            return Promise.resolve(null);
        }

        const response = await this.statamicRequest<{ user: UserData|null }>('api/v2/authentication', 'GET');

        return response.user;
    }

    loadComments(target: 'entry'|'conditions_report', uuid: string): Promise<Comment[]> {
        return this.statamicRequest<Comment[]>(`api/v2/${target}/${uuid}/comment`);
    }

    postComment(target: "entry" | "conditions_report", uuid: string, comment: string): Promise<Comment> {
        return this.statamicRequest<Comment>(`api/v2/${target}/${uuid}/comment`, 'POST', { comment: comment });
    }

    async deleteComment(uuid: string): Promise<void> {
        await this.statamicRequest(`api/v2/comment/${uuid}`, 'DELETE');
    }

    async statamicRequest<TResponse>(url: string, method: string = 'GET', body: any = null): Promise<TResponse> {
        const token = this.tokenStorage.get();

        const options: RequestInit = {
            mode: 'cors',
            credentials: 'include',
            method: method,
            headers: {
                Accept: 'application/json'
            }
        }

        if (token) {
            options.headers['Authorization'] = `Bearer ${token}`;
        }

        if (body) {
            options.headers['Content-Type'] = 'application/json'
            options.body = JSON.stringify(body)
        }

        if (this.lang) {
            options.headers['Accept-Language'] = this.lang;
        }

        const response = await fetch(`/${url}`, options)
        return response.json()
    }

    async sendPasswordResetMail(emailAddress: string): Promise<SendPasswordResetMailResponse> {
        return await this.statamicRequest('api/v2/password-reset/send', 'POST', {
            email_address: emailAddress,
        });
    }

    async resetPassword(token: string, password: string, passwordConfirmation: string): Promise<ResetPasswordResponse> {
        return await this.statamicRequest('api/v2/password-reset/reset', 'POST', {
            token: token,
            password: password,
            password_confirmation: passwordConfirmation,
        })
    }
}

export class PowderguideApiCacheDecorator implements PowderguideApiInterface {

    private cache: {
        cacheBust: string,
        contents: {
            [name: string]: { expires: number, data: any }
        }
    } | null = null;

    constructor(
        private api: PowderguideApiInterface,
        private tokenStorage: PowderguideApiTokenStorage,
        private cacheLifetimeSeconds: number,
        private cacheBust: string
    ) {
    }

    register(userProperties: RegistrationRequest): Promise<RegistrationResponse> {
        return this.api.register(userProperties);
    }


    confirmRegistration(token: string): Promise<RegistrationConfirmationResponse> {
        return this.api.confirmRegistration(token);
    }

    resendRegistrationEmail(emailAddress: string): Promise<{ success: boolean }> {
        return this.api.resendRegistrationEmail(emailAddress);
    }

    async getUser(): Promise<UserData | null> {
        const token = this.tokenStorage.get();
        if (token) {
            const userFromCache = this.getFromCache<UserData>(`user-${token}`);
            if (userFromCache) {
                return Promise.resolve(userFromCache);
            }
        }

        const user = await this.api.getUser();
        if (user) {
            this.setCache(`user-${token}`, user);
        }

        return user;
    }

    async loadLatestComments(limit: number): Promise<Comment[]> {
        const cached = this.getFromCache<Comment[]>(`latest_comments_${limit}`)
        if (cached) {
            return cached
        }

        const data = await this.api.loadLatestComments(limit)
        this.setCache(`latest_comments_${limit}`, data)

        return data
    }

    async loadSpotsForMap(activityAfter: string, activityBefore: string): Promise<SpotForMap[]> {
        const cacheKey = `spots_for_map_${activityAfter}_${activityBefore}`;
        const cached = this.getFromCache<SpotForMap[]>(cacheKey)
        if (cached) {
            return cached;
        }

        const data = await this.api.loadSpotsForMap(activityAfter, activityBefore);
        this.setCache(cacheKey, data);

        return data;
    }

    loadComments(target: "entry" | "conditions_report", uuid: string): Promise<Comment[]> {
        return this.api.loadComments(target, uuid);
    }

    postComment(target: "entry" | "conditions_report", uuid: string, comment: string): Promise<Comment> {
        return this.api.postComment(target, uuid, comment);
    }

    deleteComment(uuid: string): Promise<void> {
        return this.api.deleteComment(uuid);
    }

    login(username: string, password: string): Promise<UserData | null> {
        return this.api.login(username, password);
    }

    logout(): Promise<void> {
        const token = this.tokenStorage.get();
        if (token) {
            this.setCache(`user-${token}`, null);
        }

        return this.api.logout();
    }

    resetPassword(token: string, password: string, passwordConfirmation: string): Promise<ResetPasswordResponse> {
        return this.api.resetPassword(token, password, passwordConfirmation);
    }

    sendPasswordResetMail(emailAddress: string): Promise<SendPasswordResetMailResponse> {
        return this.api.sendPasswordResetMail(emailAddress);
    }

    private getFromCache<T>(name: string): T {
        if (this.cache === null) {
            this.cache = JSON.parse(localStorage.getItem('powderguide-api-cache') || '{}');
        }
        if (this.cache.cacheBust !== this.cacheBust) {
            this.cache = {
                cacheBust: this.cacheBust,
                contents: {},
            };
            this.setCache(name, null);
        }

        const data = this.cache && this.cache.contents && this.cache.contents[name] ? this.cache.contents[name] : false;
        if (!data) {
            return null;
        }

        if (data.expires < Date.now()) {
            this.setCache(name, null);
            return null;
        }

        return JSON.parse(JSON.stringify(data.data));
    }

    private setCache<T>(name: string, data: T): void {
        this.initializeCache();
        if (data === null) {
            delete this.cache.contents[name];
        } else {
            this.cache.contents[name] = {
                expires: Date.now() + this.cacheLifetimeSeconds * 1000,
                data,
            };
        }

        localStorage.setItem('powderguide-api-cache', JSON.stringify(this.cache));
    }

    private initializeCache(): void {
        if (this.cache === null) {
            this.cache = JSON.parse(localStorage.getItem('powderguide-api-cache') || '{}');
        }
        if (this.cache.cacheBust !== this.cacheBust) {
            this.cache = {
                cacheBust: this.cacheBust,
                contents: {},
            };
            this.setCache('', null);
        }
    }
}
